<template>
    <div>
        <el-upload class="avatar-uploader" action="/" :show-file-list="false" :before-upload="beforeFileUpload">
            <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
    </div>
</template>
<script>
import {
    updateAccountCountry,
    replaceAcountry
} from '../api/eae'
import * as XLSX from "xlsx";
export default ({
    name: 'index',
    data() {
        return {

        }
    },
    methods: {
        beforeFileUpload(file) {
            const reader = new FileReader()
            let that = this
            let successCount = 0
            let errrCout = 0
            reader.onload = e => {
                const data = e.target.result
                const workbook = XLSX.read(data, { type: 'array' })
                const secondSheet = workbook.SheetNames[0]
                const worksheet = workbook.Sheets[secondSheet]
                const results = XLSX.utils.sheet_to_json(worksheet)
                for (let i = 0; i < results.length; i++) {
                    let content = results[i]
                    let account_id = content['ID']
                    let account_country = content['国家']
                    // console.log('account_id:' + account_id + ", account_country:" + account_country)
                    // updateAccountCountry(account_id, account_country).then((res) => {
                    //     let result = res.data
                    //     if (result != null) {
                    //         successCount++
                    //     } else {
                    //         errrCout++
                    //     }
                    //     console.log('successCount:' + successCount + ", errrCout:" + errrCout)
                    // })
                    // replaceAcountry(old_country, new_country).then((res) => {

                    // })
                }
            }
            reader.readAsArrayBuffer(file)
        }
    }
})
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px !important;
    text-align: center;
}

.avatar {
    width: 120px;
    height: 120px;
    display: block;
}
</style>